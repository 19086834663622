import styled from 'styled-components'

const white = '#FFF'
const red = '#B41400'
const clearFix = () => '{ &::after, &::before { content: ""; display: table; clear: both; } }'
const remCalc = (fontSize) => `${fontSize / 16}rem`
const transition = params => `
    -webkit-transition: ${params};
    -moz-transition: ${params};
    -khtml-transition: ${params};
    -o-transition: ${params};
    transition: ${params};
`

export const StyledRelatedArticle = styled.div`
  background: ${white};
  padding: 15px;
  width: 100%;
  border: 1px solid #eee;
  margin-bottom: 15px;
  ${clearFix}
  clear: both;
  ul, li {
    list-style-type: none;
  }
  ul {
    padding-left: 20px;
    margin: 0 !important;
  }
  li {
    &::before {
      content: '\\2022';
      color: ${red};
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      vertical-align: top;
    }
  }
  h2, h3 {
    font-size: ${remCalc(14)} !important;
  }
  h2 {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 11px;
    span {
      border-bottom: 1px solid #c01d1f;
      padding-bottom: 9px;
      margin-bottom: -10px;
      position: relative;
      bottom: -1px;
    }
  }
  article {
    display: inline-block !important;
    vertical-align: top;
    margin-top: 5px;
    width: 100%;
    h3 {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0 none;
      text-transform: none;
      color: #000;
      ${transition('all linear .3s')}
    }
    a {
      text-decoration: none;
    }
    a:hover {
      h3 {
        color: ${red};
      }
    }
    p {
      color: #eee;
      display: none;
    }
  }
`

export default StyledRelatedArticle
