import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Articles, SectionMeta } from 'ion-article-cmp'
import WingBanners from '../components/WingBanners'
import { getSectionLabel } from 'ion-sections'
import Ad from '../components/Ad'
import FORMATS from '../components/AdFormats'
import { MobileElse } from 'ion-media'
import logo from '../static/general/glamour.svg'
import NoImage from '../static/no-image.png'
import ArticlesNoAdsTemplate from '../templates/Articles/FourLayoutArticlesNoAds'
import CategoryList from '../templates/Articles/BeautyAwardsCategoryList'
import NotFound from './NotFound'

const BeautyAwardsPage = (props) => (
  <>
    <Helmet
      titleTemplate='%s'
      title={props.title || getSectionLabel(props.section)}
    >
      <meta property='fb:app_id' content='1915095138801045' />
      <meta property='og:type' content='section' />
      <meta property='og:title' content={props.title || getSectionLabel(props.section)} />
      <meta property='og:image' content={logo} />
      <meta itemProp='headline' content={props.title || 'Latest News from ' + getSectionLabel(props.section)} />
      <meta name='description' content={props.description} />
      <meta itemProp='description' content={props.description} />
      <meta property='og:description' content={props.description} />
      <meta property='og:url' content={props.url} />
      <meta property='keywords' content={props.keywords} />
      <meta name='twitter:site' content={props.twitterName} />
      <meta name='twitter:creator' content={props.twitterName} />
      <meta name='twitter:title' content={props.title || getSectionLabel(props.section)} />
      <meta name='twitter:description' content={props.description} />
      <meta itemprop='image' content={logo} />
      <meta itemprop='thumbnailUrl' content={logo} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image:src' content={logo} />
    </Helmet>
    <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
    <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-1' targeting={{ leaderboard: '1' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
    <div className='wrapper'>
      <div className='beauty-awards'>
        <Articles {...props} section='beauty-awards-slider' sectionLabel={props.sectionLabel} count={20} pageSize={20} noImage={NoImage} notFound={NotFound} component={CategoryList} />
        {props.isPage0 &&
          <Articles {...props} component={ArticlesNoAdsTemplate} section={props.section} sectionLabel={props.sectionLabel} count={8} pageSize={8} noImage={NoImage} notFound={NotFound} withMore />}
        {!props.isPage0 &&
          <Articles className='article-list paged' {...props} component={ArticlesNoAdsTemplate} section={props.section} page0Limit={8} pageSize={8} noImage={NoImage} notFound={NotFound} withMore />}
      </div>
    </div>
    <MobileElse>
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
    </MobileElse>
    <SectionMeta {...props} />
  </>
)

BeautyAwardsPage.defaultProps = {
  component: ArticlesNoAdsTemplate,
  section: 'beauty/beauty-awards'
}

export default connect((state) => ({ articles: state.articles }))(BeautyAwardsPage)
