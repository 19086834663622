import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchMostReadArticles } from '../../store/mostReadArticles'
import NoResults from '../static/icons/no-search-results-v2.svg'
import NoImage from '../static/no-image.png'
import { Articles } from 'ion-article-cmp'
import ArticleCard from '../components/ArticleCard'

const MostReadArticles = ({ articles, authorName, noImage, moreButton }) => {
  // Note that we don't have the format of a v2 endpoint section so must supply image
  return (
    <>
      {articles.map((article, index) => {
        article.image = article.images[0]
        return (
          <ArticleCard key={article.contentKey} article={article} noImage={noImage} />
        )
      })}
    </>
  )
}

class NotFound extends Component {
  constructor (props) {
    super(props)
    const { staticContext } = props
    if (staticContext) {
      staticContext.is404 = true
    }
  }

  render () {
    // console.log('404', this.props)
    return (
      <>
        <div className='main-content'>
          <div className='wrapper no-results'>
            <img src={NoResults} alt='404 Page not found' />
            <h1>404 Page not found</h1>
          </div>
        </div>
        <div className='wrapper'>
          <div className='search-page' itemType='http://schema.org/ItemList'>
            <h1><span>Most Read</span></h1>
            <Articles
              className='article-list paged' component={MostReadArticles} page0Limit={8} pageSize={8}
              fetchArticles={this.props.fetchMostReadArticles} noImage={NoImage} articles={this.props.articles}
              notFound={() => (<div>Oops! No articles found.</div>)} fetchMoreArticles={() => {}}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({ articles: state.mostReadArticles })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchMostReadArticles }, dispatch)

// export default NotFound
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotFound))
