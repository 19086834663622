import React from 'react'
import { Link } from 'react-router-dom'
import { Mobile, MobileElse } from 'ion-media'
import { ImageOrNoImage } from 'ion-image'

const ContentHubHorArticleCard = ({ article, noImage, imageSize }) => (
  <article key={article.contentKey} aria-label='article'>
    <Link to={'/' + article.getCanonicalUri()}>
      <div className='content'>
        <h4>{article.headline}</h4>
        <p>By <strong>{article.getAuthor()}</strong></p>
        <p>{article.formatDate()}</p>
      </div>
      <div className='article-list-img'>
        <Mobile>
          <ImageOrNoImage shape='square' width={320} image={article.image} alt={article.headline} noImage={noImage} />
        </Mobile>
        <MobileElse>
          <ImageOrNoImage shape='square' width={imageSize} image={article.image} alt={article.headline} noImage={noImage} />
        </MobileElse>
      </div>
    </Link>
  </article>
)

export default ContentHubHorArticleCard
