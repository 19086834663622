import React, { Fragment } from 'react'
import ArticleCard from '../../components/ArticleCard'

const Default = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton }) => {
  return (
    <>
      <h1>{sectionLabel}</h1>
      {articles.map((article, index) => {
        return (
          <Fragment key={article.contentKey}>
            <ArticleCard article={article} noImage={noImage} />
          </Fragment>
        )
      })}
      {moreButton}
    </>
  )
}

export default Default
