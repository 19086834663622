import styled from 'styled-components'

const grey = '#707070'

export const AuthorArticlesContainer = styled.div`
  overflow-x: scroll;
`

export const StyledAuthorArticles = styled.div`
  max-width: 1220px;
  width: 100%;
  margin: 40px auto;
  p{
    color: ${grey};
    font-size:14px;
    margin: 0;
  }
  h5 {
    font-size: 19px;
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: #D4D4D4;
    padding-bottom: 10px;
    margin: 0;
    a {
      color: #B41400 !important;
      &:hover {
        color: #000 !important;
      }
    }
  }
`

export const StyledArticleContent = styled.div`
  display:grid;
  gap: 40px;
  grid-template-columns: 100%;
  padding: 20px 0;
  @media (min-width: 1024px){
    grid-template-columns: repeat(5, calc((100% - 80px) / 3));
    gap: 40px;
  }
  article {
    position: relative;
    a {
      display: grid;
      gap: 10px;
      grid-template-columns: 80px auto;
      color: #000000 !important;
      &:hover {
        color: #B41400 !important;
      }
    }
  }
  h6{
    font-size: 16px;
    white-space: normal;
  }
  @media screen and (max-width: 1023px) {
    article::after {
      content: '';
      border-bottom: 1px solid #D4D4D4;
      position: absolute;
      bottom: -20px;
      width: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    article::after {
      content: '';
      border-right: 1px solid #D4D4D4;
      position: absolute;
      right: -20px;
      height: 100%;
    }
    article:last-of-type::after {
        border-right: 0;
    }
  }
`
