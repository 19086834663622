import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ImageOrNoImage } from 'ion-image'
import next from '../../static/icons/gallery-next.svg'
import prev from '../../static/icons/gallery-prev.svg'

function CategoryList ({ articles, noImage }) {
  const [showLeftBtn, setShowLeftBtn] = useState(false)
  const [showRightBtn, setShowRightBtn] = useState(true)
  const categoryListRef = useRef(null)

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = categoryListRef.current

    setShowLeftBtn(scrollLeft > 0)
    setShowRightBtn(scrollLeft < scrollWidth - clientWidth)
  }

  const scrollList = (direction) => {
    const currentScrollPosition = categoryListRef.current?.scrollLeft
    const scrollAmount = categoryListRef.current?.clientWidth * 0.8 // 80% of the view width

    if (categoryListRef.current) {
      if (direction === 'left') {
        categoryListRef.current.scrollLeft = currentScrollPosition - scrollAmount
      } else {
        categoryListRef.current.scrollLeft = currentScrollPosition + scrollAmount
      }
    }
  }

  useEffect(() => {
    categoryListRef.current?.addEventListener('scroll', handleScroll)
    return () => categoryListRef.current?.removeEventListener('scroll', handleScroll)
  }, [categoryListRef])

  return (
    <div className='category-container'>
      <h2>Explore by Category</h2>
      {showLeftBtn && <button className='scroll-btn left-btn' onClick={() => scrollList('left')}><img src={prev} /></button>}
      <ul className='category-list' ref={categoryListRef}>
        {articles.map(article => (
          <li key={article.contentKey} className='category-item'>
            <Link to={'/' + article.getCanonicalUri()}>
              <ImageOrNoImage shape='16x9' width={110} image={article.image} alt={article.headline} noImage={noImage} />
              <span>{article.displayTag?.label || 'Label'}</span>
            </Link>
          </li>
        ))}

      </ul>
      {showRightBtn && <button className='scroll-btn right-btn' onClick={() => scrollList('right')}><img src={next} /></button>}
    </div>
  )
}

export default CategoryList
