import React from 'react'
import { StyledRelatedInArticle, StyledInnerDiv, StyledHeadlineDiv, StyledBoxDiv } from './InArticleRelated.css'
import { Link } from 'react-router-dom'
import { ImageOrNoImage } from 'ion-image'
import NoImage from '../static/no-image.png'

const SingleRelatedArticle = ({ relatedArticle, abstract }) => {
  return (
    <StyledRelatedInArticle>
      <StyledInnerDiv>
        {relatedArticle.map(relatedArticle => (
          <StyledBoxDiv key={'ra-' + relatedArticle.uuid}>
            {relatedArticle.externalUrl &&
              <Link to={'/' + relatedArticle.externalUrl}>
                <ImageOrNoImage image={{ url: relatedArticle.imageUrl }} width={120} alt='Related Article Image' shape='square' noImage={NoImage} />
                <StyledHeadlineDiv>
                  <h4>{relatedArticle.externalUrl.split('/')[0]}</h4>
                  <h3>{relatedArticle.teaserHeadline}</h3>
                  <span>Read more</span>
                </StyledHeadlineDiv>
              </Link>}
          </StyledBoxDiv>
        ))}
      </StyledInnerDiv>
    </StyledRelatedInArticle>
  )
}

export default SingleRelatedArticle
