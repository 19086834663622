import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Articles, SectionMeta } from 'ion-article-cmp'
import WingBanners from '../components/WingBanners'
import { getSectionLabel } from 'ion-sections'
import Ad from '../components/Ad'
import FORMATS from '../components/AdFormats'
import Sidebar from '../components/Sidebar'
import logo from '../static/general/glamour.svg'
import NoImage from '../static/no-image.png'
import DefaultTemplate from '../templates/Articles/Default'
import NotFound from './NotFound'
import ArticleCard from '../components/ArticleCard'
import { MobileElse, DesktopTablet } from 'ion-media'
import MagazineCover from '../components/MagazineCover'
import useHasMounted from '../components/useHasMounted'
import { DefaultSectionPageSkeleton } from '../components/Skeletons'

const DefaultSectionPage = (props) => {
  const hasMounted = useHasMounted()
  // const Template = DefaultTemplateSkeleton  // Uncomment this to run the skeleton only
  const Template = props.template || DefaultTemplate
  return (
    <>
      <Helmet
        titleTemplate='%s'
        title={props.title || getSectionLabel(props.section)}
      >
        <meta property='fb:app_id' content='293175074032541' />
        <meta property='og:type' content='section' />
        <meta property='og:title' content={props.title || getSectionLabel(props.section)} />
        <meta property='og:image' content={logo} />
        <meta itemProp='headline' content={props.title || 'Latest News from ' + getSectionLabel(props.section)} />
        <meta name='description' content={props.description} />
        <meta itemProp='description' content={props.description} />
        <meta property='og:description' content={props.description} />
        <meta property='og:url' content={props.url} />
        <meta property='keywords' content={props.keywords} />
        <meta name='twitter:site' content={props.twitterName} />
        <meta name='twitter:creator' content={props.twitterName} />
        <meta name='twitter:title' content={props.title || getSectionLabel(props.section)} />
        <meta name='twitter:description' content={props.description} />
        <meta itemprop='image' content={logo} />
        <meta itemprop='thumbnailUrl' content={logo} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image:src' content={logo} />
      </Helmet>
      {props.isPage0 &&
        <Articles
          {...props} noDiv className='' loading={DefaultSectionPageSkeleton} component={(p) => (
            <>
              <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
              <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-1' targeting={{ leaderboard: '1' }} collapseEmptyDiv className='advert-leaderboard-1' {...FORMATS.leaderboard} />
              <div className='wrapper article-listing'>
                <div className='article-list'>
                  <Template {...p} />
                </div>
                {hasMounted
                  ? (
                    <Sidebar {...props}>
                      <DesktopTablet>
                        <MagazineCover />
                      </DesktopTablet>
                    </Sidebar>
                    )
                  : (
                    <Sidebar {...props} />
                    )}
              </div>
              <MobileElse>
                {hasMounted &&
                  <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-3' {...FORMATS.leaderboard} />}
              </MobileElse>
            </>
          )} section={props.section} sectionLabel={props.sectionLabel} sectionsLabels={props.sectionsLabels} count={15} pageSize={14} noImage={NoImage} notFound={NotFound} withMore
        />}
      {!props.isPage0 &&
        <Articles
          {...props} className='' component={(p) => (
            <div className='wrapper article-listing'>
              <div className='article-list paged'>
                <h1>{p.sectionLabel}</h1>
                {p.articles.map((article) => <ArticleCard key={article.contentKey} article={article} noImage={p.noImage} />)}
                {p.moreButton}
              </div>
              {hasMounted
                ? (
                  <Sidebar {...props}>
                    <DesktopTablet>
                      <MagazineCover />
                    </DesktopTablet>
                  </Sidebar>
                  )
                : (
                  <Sidebar {...props} />
                  )}
            </div>
          )} section={props.section} page0Limit={15} pageSize={14} noImage={NoImage} notFound={NotFound} withMore
        />}
      <SectionMeta {...props} />
    </>
  )
}
DefaultSectionPage.defaultProps = {
  component: DefaultTemplate,
  section: 'homepage'
}

export default connect((state) => ({ articles: state.articles }))(DefaultSectionPage)
