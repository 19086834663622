import React, { Fragment } from 'react'
import ArticleCard from '../../components/ArticleCard'
import Ad from '../../components/Ad'
import FORMATS from '../../components/AdFormats'
import { Desktop, Mobile, MobileElse } from 'ion-media'
import MagazineCover from '../../components/MagazineCover'
import useHasMounted from '../../components/useHasMounted'
import FollowConcept from '../../components/FollowConcept'

const Default = ({ articles, section, noImage, isConnected, sectionLabel, moreButton, adPath, sectionsLabels }) => {
  const hasMounted = useHasMounted()
  return (
    <>
      <div>
        <h1>{sectionLabel}</h1>
        {sectionsLabels && sectionsLabels[0] && sectionsLabels[0].uuid &&
          <FollowConcept
            topic={{ topic: sectionsLabels[0].uuid, type: 'tag', name: sectionsLabels[0].label }}
            followText='Follow Topic'
            unFollowText='Unfollow Topic'
          />}
      </div>
      {articles.map((article, index) => {
        return (
          <Fragment key={article.contentKey}>
            {hasMounted &&
              <Desktop>
                {index === 15 && (
                  <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${adPath || section}`} targeting={{ leaderboard: 'vm' }} className='advert-leaderboard-vm' {...FORMATS.leaderboard2} />
                )}
              </Desktop>}
            <ArticleCard article={article} noImage={noImage} />
            {hasMounted &&
              <MobileElse>
                {index === 4 && (
                  <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${adPath || section}`} slotId='leaderboard-2' targeting={{ leaderboard: '2' }} collapseEmptyDiv className='advert-leaderboard-2' {...FORMATS.leaderboard2} />
                )}
              </MobileElse>}
            <Mobile>
              {(index === 2) && (
                <MagazineCover />
              )}
            </Mobile>
            {Number.isInteger((index + 5) / 4) && (
              <Mobile>
                <article className='advert'>
                  <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${adPath || section}`} slotId={'repeatMpu-' + index} targeting={{ mpu: index < 14 ? ((index + 1) / 4) : 'vm' }} collapseEmptyDiv className='align-all' adNotice {...FORMATS.mpu} />
                </article>
              </Mobile>
            )}
          </Fragment>
        )
      })}
      {moreButton}
    </>
  )
}

export default Default
