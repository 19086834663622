import React from 'react'
import { getCanonical, getImageSize, getImage, getTeaser } from '../components/ui/articleutils'
import { ImageOrNoImage } from 'ion-image'
import NoImage from '../static/no-image.png'
import { AuthorArticlesContainer, StyledAuthorArticles, StyledArticleContent } from './AuthorArticles.css'
import StyledLink from './StyledLink'

const AuthorArticles = ({ articles, to, authorName }) => (
  <StyledAuthorArticles>
    <p>Recent stories by:</p>
    <h5>
      <StyledLink to={to}>
        {authorName}
      </StyledLink>
    </h5>
    <AuthorArticlesContainer>
      <StyledArticleContent>
        {articles && articles.map(article => (
          <article key={'auth-' + article.uuid}>
            <StyledLink to={'/' + getCanonical(article)}>
              <div>
                <ImageOrNoImage image={getImage(article)} width={getImageSize('240')} alt={getTeaser(article)} shape='square' noImage={NoImage} />
              </div>
              <div>
                <h6>{getTeaser(article)}</h6>
              </div>
            </StyledLink>
          </article>
        ))}
      </StyledArticleContent>
    </AuthorArticlesContainer>
  </StyledAuthorArticles>
)

export default AuthorArticles
