import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import Helmet from 'react-helmet'

import { fetchArticle, setPreviewArticle, submitCompetition, serverArticleRender } from '../../store/article'
import { fetchArticles } from '../../store/articles'
import { getAllFormFields } from 'ion-dom'
import IonArticle from 'ion-article'
import { Mobile, Tablet, Desktop, DesktopElse, defaultWidth } from 'ion-media'
import { ArticleImage, ArticleMeta, Articles } from 'ion-article-cmp'
import Ad from '../components/Ad'
import WingBanners from '../components/WingBanners'
import FORMATS from '../components/AdFormats'
import Video from '../components/Video'
import { ImageOrNoImage, ImageCalculator, calcImageSrcUrl } from 'ion-image'
import ArticleSocialShare from '../components/ArticleSocialShare'
import ArticleGallery from '../components/ArticleGallery'
import NotFound from './NotFound'
import unknownImage from '../static/no-image.png'
import Sidebar from '../components/Sidebar'
import { ArticlePageSkeleton } from '../components/Skeletons'
import OfflineImage from '../static/icons/broken-connection.svg'
import RelatedArticle from '../components/RelatedArticle'
import SingleRelatedArticle from '../components/InArticleRelated'
import MagazineCover from '../components/MagazineCover'
import SubscribeBlock from '../components/SubscribeBlock'
import { bm } from '../db'
import articleBookmark from '../images/brands/icons/bookmark-ico.svg'
import articleBookmarked from '../images/brands/icons/bookmarked-ico.svg'
import AuthorArticles from '../components/AuthorArticles'
import CategoryList from '../templates/Articles/BeautyAwardsCategoryList'

export class Article extends Component {
  constructor (props) {
    super(props)
    this.state = { hasMounted: false, bookmarked: false }
    this.scriptloaded = this.scriptloaded.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.processVideo = true
    if (this.props.article && (this.props.previewArticle || this.props.article.contentKey === this.props.contentKey)) {
      this.props.serverArticleRender(this.props.article)
    }
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  validateName (name) {
    const re = /^[`a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' -]{2,45}$/i
    return name.match(re)
  }

  validateField (field) {
    if (field.name.match('email')) {
      if (!field.value || !this.validateEmail(field.value)) {
        return 'Please a provide a valid email'
      } else {
        return true
      }
    } else if (field.name.match('fullNameShort')) {
      if (!field.value || !this.validateName(field.value)) {
        return 'Please provide a valid Name'
      } else {
        return true
      }
    } else {
      return true
    }
  }

  showErrorMessage (id, result) {
    const competitionFormNode = document.getElementById(this.props.article.competition.form)
    if (competitionFormNode) {
      const input = document.getElementById(id)
      input.insertAdjacentHTML('afterend', '<p class="form-error-validation" style="color:red">' + result + '</p>')
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    const elements = document.getElementById(this.props.article.competition.form).elements
    this.props.submitCompetition(getAllFormFields(elements))
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    if (this.props.article && this.props.previewArticle) {
      return
    }
    // eslint-disable-next-line eqeqeq
    if (prevProps.contentKey != this.props.contentKey ||
      (prevProps.hasError && !prevProps.isConnected && this.props.isConnected)) {
      this.props.fetchArticle(this.props.contentKey)
    }
    if (this.props.article) {
      this.scripts = 0
      this.processArticle()
      bm.messages.filter(entry => {
        return entry.uuid === this.props.article.contentKey
      }).count().then(val => {
        if (val < 1) {
          if (this.state.bookmarked) {
            this.setState({ bookmarked: false })
          }
        } else {
          if (!this.state.bookmarked) {
            this.setState({ bookmarked: true })
          }
        }
      })
    }
    if (this.props.message) {
      const errorNode = document.getElementsByClassName('form-error-validation')
      while (errorNode.length > 0) {
        errorNode[0].parentNode.removeChild(errorNode[0])
      }
      const errors = this.props.message
      Object.keys(errors).map((errElement) =>
        errors[errElement].map((message) => this.showErrorMessage('fields-' + errElement, message))
      )
    }
  }

  UNSAFE_componentWillMount () {
    // Fetch the first time this component is mounted
    if (this.props.ArticlePushContext) {
      this.props.setPreviewArticle(this.props.ArticlePushContext)
    } else {
      if (this.props.article && this.props.previewArticle) {
        return
      }
      const now = new Date()
      const lastFetch = new Date(this.props.lastFetch ? this.props.lastFetch : 0)
      // eslint-disable-next-line eqeqeq
      if (!this.props.article || this.props.contentKey != this.props.article.contentKey || now - lastFetch > (60 * 1000)) {
        this.props.fetchArticle(this.props.contentKey)
      }
    }
  }

  scriptloaded () {
    this.scripts--
    console.log('Script loaded', this.scripts, 'left to load')
    if (this.scripts === 0) {
      console.log('All scripts loaded')
      this.nodeScriptReplace(document.getElementById('article-more-body'), false)
    }
  }

  nodeScriptIs (node) {
    return node.tagName === 'SCRIPT'
  }

  nodeScriptClone (node, src, callback) {
    if (!src && node.src) {
      return node
    }
    if (src && !node.src) {
      return node
    }
    const script = document.createElement('script')
    script.text = node.innerHTML
    for (let i = node.attributes.length - 1; i >= 0; i--) {
      script.setAttribute(node.attributes[i].name, node.attributes[i].value)
    }
    if (callback) {
      return callback(script)
    }
    return script
  }

  nodeScriptReplace (node, src, callback) {
    if (!node) { return }
    if (this.nodeScriptIs(node) === true) {
      node.parentNode.replaceChild(this.nodeScriptClone(node, src, callback), node)
    } else {
      let i = 0
      const children = node.childNodes
      while (i < children.length) {
        this.nodeScriptReplace(children[i++], src, callback)
      }
    }
  }

  resizeImages (width, bodyHTML) {
    const images = bodyHTML.match(/<img [^>]+>/g)
    if (images) {
      const calc = new ImageCalculator()
      const shape = 'original'
      const resizeURL = process.env.RAZZLE_RESIZE_URL

      for (let i = 0; i < images.length; i++) {
        let image = {}
        try {
          image = JSON.parse(images[i]
            .replace(/['"][ ]+/g, '",')
            .replace(/([ ,])([a-z0-9-]+)=/gi, '$1"$2":')
            .replace(/<img\s+/, '{')
            .replace(/[,/ ]*>$/, '}')
          )
        } catch (e) {}

        let imgOffsetX = parseInt(image.offsetx) || 0
        let imgOffsetY = parseInt(image.offsety) || 0
        let imgCropWidth = parseInt(image.cropwidth) || 0
        let imgCropHeight = parseInt(image.cropwidth) || 0
        const imageWidth = parseInt(image.imageWidth)
        const imageHeight = parseInt(image.imageHeight)
        if ('imageCrop' in image) {
          const crops = image.imageCrop.split('/')
          imgOffsetX = Math.round((parseFloat(crops[0]) || 0) * imageWidth)
          imgOffsetY = Math.round((parseFloat(crops[1]) || 0) * imageHeight)
          imgCropWidth = Math.round((parseFloat(crops[2]) || 1) * imageWidth)
          imgCropHeight = Math.round((parseFloat(crops[3]) || 1) * imageHeight)
        }
        const height = calc.calcHeight(shape, width) || width * 100
        const { offsetx, offsety, cropWidth, cropHeight } = calc.getCropCoordsForShape(shape,
          width, height, imageWidth, imageHeight,
          imgOffsetX, imgOffsetY, imgCropWidth, imgCropHeight,
          0, 0)
        const src = calc.buildImageUrl(resizeURL, '', width, height, image.src, offsetx, offsety, cropWidth, cropHeight)
        bodyHTML = bodyHTML.replace(images[i], `<img class="${image.class}" src="${src}" loading="lazy" width="${width}">`)
      }
    }
    return bodyHTML
  }

  renderAuthors (article) {
    if (article.authors) {
      return article.authors.map((author, index, arr) => {
        if (arr.length - 1 === index) {
          // last one
          return <strong key={index}>{author.hasAuthorPage ? <Link to={'/' + author.slug}>  {author.name} </Link> : author.name}</strong>
        } else {
          return <strong key={index}>{author.hasAuthorPage ? <Link to={'/' + author.slug}>  {author.name}, </Link> : author.name + ', '}</strong>
        }
      })
    }
  }

  processArticle () {
    const articleNode = document.getElementById('article-more-body')
    if (articleNode) {
      this.scripts = 0
      this.nodeScriptReplace(articleNode, true, (node) => {
        this.scripts++
        node.async = false
        node.onload = this.scriptLoaded
        return node
      })
    }
    if (!this.scripts) {
      this.nodeScriptReplace(articleNode, false)
      this.scripts++
    }
    if (this.processVideo) {
      for (const v of document.getElementById('article-more-body').querySelectorAll('video.ion-video')) {
        const sources = []
        for (const s of v.getElementsByTagName('source')) {
          sources.push({
            src: s.getAttribute('src'),
            type: s.getAttribute('type')
          })
        }
        const v2 = document.createElement('div')
        v2.style.cssText = 'clear:both;'
        let branding = {}
        try {
          if (v.hasAttribute('data-channel')) {
            branding = JSON.parse(decodeURIComponent(v.getAttribute('data-channel')))
          }
        } catch (e) {
          branding = {}
        }
        const videoRoot = createRoot(v2)
        videoRoot.render(
          <div className='video'>
            <Video
              sources={sources}
              poster={v.getAttribute('poster')}
              channel={v.getAttribute('data-channel')}
              branding={branding}
              autoplay={false}
              width='650'
              fluid
              preload='metadata'
              controls
            />
          </div>)
        v.parentElement.replaceChild(v2, v)
        console.log('Updated videojs video')
      }
      this.processVideo = false
    }
    if (this.props.article.competition) {
      const competitionFormNode = document.getElementById(this.props.article.competition.form)
      if (competitionFormNode) {
        competitionFormNode.onsubmit = this.handleSubmit
      }
    }
  }

  handleBookmark (article, bookmarked) {
    const collectionMax = 10
    if (bookmarked) {
      return bm.messages.where('uuid').equals(article.contentKey).delete()
    } else {
      return bm.messages.add({ uuid: article.contentKey, article }).then(id =>
        bm.messages.where('id').below(id - (collectionMax - 1)).delete()
      )
    }
  }

  componentDidMount () {
    if (this.props.article) {
      this.processArticle()
    }
    this.setState({ hasMounted: true })
  }

  render () {
    // Redirect to the correct canonical
    if (this.props.checkCanonical && (this.props.canonical !== this.props.match.url)) {
      return <Redirect to={this.props.canonical} />
    }
    // Server-side render doesn't always catch the state changes, so check always
    if (typeof window === 'undefined' && this.props.canonical && (this.props.canonical !== this.props.location.pathname)) {
      return <Redirect to={this.props.canonical} />
    }
    if (this.props.hasError && !this.props.is404) {
      if (this.props.isConnected) {
        return (
          <div>
            <Helmet title='ERROR' />
            <div className='wrapper'>
              <div className='article-page'>
                <article role='contentinfo' aria-label='article' className='no-elated'>
                  <h1>Unexpected Error</h1>
                  <p>Error: {this.props.error}</p>
                </article>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <Helmet title='Offline' />
            <div className='wrapper no-results'>
              <img src={OfflineImage} alt='You are offline' />
              <h1>You are offline</h1>
              <p>This article is not available as you are currently offline. Please re-establish your Internet connection and try again.</p>
            </div>
          </div>
        )
      }
    }
    if (this.props.is404 || (this.props.hasFetched && this.props.article)) {
      if (this.props.is404 || (!this.props.ArticlePushContext && this.props.article.titleKey !== process.env.RAZZLE_TITLE_KEY)) {
        return (
          <>
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/404`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' {...FORMATS.leaderboard} />
            <NotFound />
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/404`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-bot' {...FORMATS.leaderboard} />
          </>
        )
      }
      const article = new IonArticle(this.props.article)
      const resizeURL = process.env.RAZZLE_RESIZE_URL
      const gallery = article.images && article.images.length > 1 ? article.images.slice(1) : []
      let tagsList = []
      if (article.primaryTag) {
        tagsList.push(article.primaryTag)
      }
      if (article.secondaryTags) {
        tagsList = tagsList.concat(article.secondaryTags)
      }
      const tags = tagsList.slice(0, 3)
      if (article.images) {
        article.images = article.images.slice(0, 1)
      }
      const published = article.formatDate()
      const mainImage = !article.videos && article.getImageObject()
      const headline = article.headline
      let imageWidth = 640
      if (defaultWidth > 500) {
        imageWidth = 1456
      }
      if (defaultWidth > 1024) {
        imageWidth = 1180
      }
      const socialImageSrcUrl = article.socialTeaserImage ? calcImageSrcUrl(article.socialTeaserImage, 960, 'square', false) : calcImageSrcUrl(article.getImageObject(), 960, 'square', false)

      const canonicalUri = article.getCanonicalUri()
      const proposedArticles = []
      const competitionSlug = article.getCompetitionSlug()
      let competitionPayload = '<p>This competition expired</p>'
      if (competitionSlug && !article.competitionExpired) {
        competitionPayload = article.competition.competition
        if (this.props.hasErrorSubmit || !this.props.hasSubmitted) {
          competitionPayload += `<script>errors=${JSON.stringify(this.props.message || false)};formData=${JSON.stringify(this.props.formData || {})}</script>`
        }
        if (this.props.hasSubmitted) {
          competitionPayload = `<form name='${competitionSlug}-form'>Thank you for your submission</form>`
          if (competitionSlug === 'adidas-glamour') {
            const adidasLink = '<a href="https://shop.adidas.co.za/all-products/?collection=adicolor&utm_source=AdieEmail&utm_campaign=generic_arc-adicolor-2019-02-14&utm_medium=text&utm_term=text-shop-adicolor" rel="nofollow noopener" target="_blank">please click here and sign up to complete your entry.</a>'
            competitionPayload = `<form name='${competitionSlug}-form'>Thank you for your submission, ${adidasLink}</form>`
          }
        }
      }
      article.bodyHTML = article.setCompetition(competitionSlug, competitionPayload)
      article.bodyHTML = this.resizeImages(imageWidth, article.bodyHTML)
      article.bodyHTML = article.bodyHTML.replace('<!-- [AD_SLOT] -->', '<ad-slot/>')
      // For Glamour only - remove the first image which appears before the opening <p> tag
      article.bodyHTML = article.bodyHTML.replace(/^(<p\/><figure>|<figure>)(.*?)<\/figure>(.*?)<p>/g, '$3<p>')
      article.bodyHTML = '<!-- C-ON- TEXT_CONTENT_START --><!--sse-->' + article.bodyHTML + '<!--/sse--><!-- C-ON- TEXT_CONTENT_END -->'
      // change aspect ratio of in-article YouTube iframes
      article.bodyHTML = article.bodyHTML.replace(/<iframe([0-9a-z=%"\\ ]+?)(https?:\/\/(?:www\.)?youtu(\.be|be\.com))/g, '<iframe style="aspect-ratio:16/9;width:100%;"$1$2')
      const bodyElements = article.bodyHTML.split('<ad-slot/>')
      if (this.props.readNext) {
        const lengthArray = this.props.readNext.length < 9 ? this.props.readNext.length : 9
        for (let i = 0; i < lengthArray; i++) {
          if (parseInt(this.props.readNext[i].contentKey) !== parseInt(this.props.contentKey)) {
            proposedArticles.push(new IonArticle(this.props.readNext[i]))
          }
        }
      }
      if (proposedArticles.length > 8) {
        proposedArticles.pop()
      }
      let videoSources = []
      if (article.videos && article.videos[0].sources) {
        const sources = article.videos[0].sources
        videoSources = Object.keys(sources).map(key => {
          return { src: sources[key].url || sources[key].mp4.url, type: sources[key].type || 'video/mp4', label: key }
        })
      }
      const articleImage = Object.assign({ url: '' }, article.getImageObject())
      const imageCredit = article.formatImageCredit()
      const imageCaption = article.formatImageCaption()
      const relatedArticlePresent = this.props.article.relatedArticles.length > 0

      return (
        <>
          <WingBanners useSmall={this.props.useSmall} isConnected={this.props.isConnected} location={this.props.location} onSlotRenderEndedLeft={this.props.onSlotRenderEndedLeft} onSlotRenderEndedRight={this.props.onSlotRenderEndedRight} />
          <div className='main-article'>
            <Helmet title={headline}>
              <meta property='fb:app_id' content='293175074032541' />
              <meta property='og:type' content='article' />
              <meta property='og:title' content={article.socialTeaserHeadline || headline} />
              <meta property='og:description' content={article.socialAbstract || article.abstract} />
              <meta property='og:url' content={process.env.RAZZLE_SITE_URL + this.props.canonical} />
              <meta property='keywords' content={this.props.keywords || [].concat(article.primaryTag).concat(article.secondaryTags).map(tag => tag && tag.label).join(', ')} />
              <meta property='og:image' content={resizeURL + socialImageSrcUrl} />
              <meta property='og:image:width' content={article?.images?.[0].width || 0} />
              <meta property='og:image:height' content={article?.images?.[0].height || 0} />
              <meta property='og:image:alt' content={article?.images?.[0].caption || article.headline} />
              <meta itemProp='headline' content={headline} />
              <meta itemProp='description' name='description' content={article.abstract} />
              <meta itemProp='dateModified' content={article.modified} />
              <meta itemProp='datePublished' content={article.published} />
              <meta itemProp='identifier' content={article.contentKey} />
              <meta name='twitter:site' content={this.props.twitterName} />
              <meta name='twitter:creator' content={this.props.twitterName} />
              <meta name='twitter:title' content={headline} />
              <meta name='twitter:description' content={article.abstract} />
              <meta itemprop='image' content={resizeURL + socialImageSrcUrl} />
              <meta itemprop='thumbnailUrl' content={resizeURL + calcImageSrcUrl(article.getImageObject(), 100, 'square', false)} />
              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:image' content={resizeURL + socialImageSrcUrl} />
              <meta property='twitter:image:alt' content={article?.images?.[0].caption || article.headline} />
              <meta itemProp='inLanguage' content='en' />
              {article.canonicalUrl &&
                <link rel='canonical' itemprop='url' href={article.canonicalUrl} />}
            </Helmet>
            <ArticleMeta article={article} />
            {this.props.location.pathname.startsWith('/beauty/beauty-awards/')
              ? <div className='beauty-awards'><Articles {...this.props} section='beauty-awards-slider' count={20} pageSize={20} noImage={unknownImage} notFound={NotFound} component={CategoryList} /></div>
              : <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${this.props.location.pathname}`} slotId='leaderboard-1' targeting={{ leaderboard: '1' }} collapseEmptyDiv className='advert-leaderboard-1' {...FORMATS.leaderboard} />}
            <div className='wrapper'>
              <div className='article-page'>
                <article role='contentinfo' aria-label='article'>
                  <p className='section'><Link to={'/' + article.getSectionId()}>{article.sectionLabel}</Link></p>
                  <h1>{headline}</h1>
                  <div itemProp='articleBody' className='article-body'>
                    <aside className='meta'>
                      <div className='author-meta'>
                        <a href='#' onClick={() => { this.handleBookmark(this.props.article, this.state.bookmarked); return this.setState({ bookmarked: !this.state.bookmarked }) }}>
                          <img src={this.state.bookmarked ? articleBookmarked : articleBookmark} alt='Bookmark article to read later' />
                        </a>
                        <div>
                          <p>{this.renderAuthors(article) || article.author}</p>
                          <p>{published}</p>
                        </div>
                      </div>
                    </aside>
                    {mainImage && mainImage.url && article.images.length === 1 &&
                      <>
                        <Mobile>
                          <ArticleImage
                            key={article.contentKey} image={articleImage} href={resizeURL + calcImageSrcUrl(article.getImageObject(), 640, 'original')} imageCaption={imageCaption} altText={mainImage.altText}
                            imageCredit={imageCredit}
                          />
                        </Mobile>
                        {this.state.hasMounted &&
                          <>
                            <Tablet>
                              <ArticleImage
                                key={article.contentKey} image={articleImage} href={resizeURL + calcImageSrcUrl(article.getImageObject(), 1456, 'original')} imageCaption={imageCaption} altText={mainImage.altText}
                                imageCredit={imageCredit}
                              />
                            </Tablet>
                            <Desktop>
                              <ArticleImage
                                key={article.contentKey} image={articleImage} href={resizeURL + calcImageSrcUrl(article.getImageObject(), 1180, 'original')} imageCaption={imageCaption} altText={mainImage.altText}
                                imageCredit={imageCredit}
                              />
                            </Desktop>
                          </>}
                      </>}
                    {!mainImage && article.videos && article.videos[0].sources &&
                      <figure className='video'>
                        <Video
                          sources={videoSources}
                          poster={article.videos[0].thumbnailURL}
                          autoplay={false}
                          width='670'
                          fluid
                          preload='metadata'
                          channel={article.videos[0].channel}
                          branding={article.videos[0].branding}
                          controls
                        />
                      </figure>}
                    <div className='articleBodyMore' id='article-more-body'>
                      {
                      bodyElements.map((element, index) => {
                        switch (index) {
                          case 1: // Related Article
                            return (
                              <Fragment key={index}>
                                {relatedArticlePresent &&
                                  <SingleRelatedArticle relatedArticle={this.props.article.relatedArticles.slice(0, 1)} unknownImage={unknownImage} />}
                                {!relatedArticlePresent &&
                                  <div id='inarticlead' className='in-article-ad'>
                                    <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${this.props.location.pathname}`} slotId='mpu-4' targeting={{ mpu: '4' }} collapseEmptyDiv className='advert advert-inarticle' {...FORMATS.mpu4} />
                                  </div>}
                                <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                              </Fragment>
                            )
                          case 2: // in-article-ad
                            return (
                              <Fragment key={index}>
                                {relatedArticlePresent &&
                                  <div id='inarticlead' className='in-article-ad'>
                                    <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${this.props.location.pathname}`} slotId='mpu-4' targeting={{ mpu: '4' }} collapseEmptyDiv className='advert advert-inarticle' {...FORMATS.mpu4} />
                                  </div>}
                                <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                              </Fragment>
                            )
                        }
                        return <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                      })
                    }
                    </div>
                    {gallery.length > 0 &&
                      <ArticleGallery gallery={gallery} width={defaultWidth} />}
                    <SubscribeBlock />
                    <div className='article-tags'>
                      {tags.map((tag, index) => (
                        <Fragment key={tag.sectionSlug + '/' + tag.slug}><Link to={'/' + tag.sectionSlug + '/' + tag.slug} key={index}>{tag.label}</Link>{tags.length > index + 1 ? ' | ' : ''}</Fragment>
                      ))}
                    </div>
                    {/* Author Articles Start */}
                    {this.props.article.authors && this.props.article.authors[0].hasAuthorPage && this.props.authorArticles &&
                      <AuthorArticles articles={this.props.authorArticles.filter(article => article.contentKey !== this.props.article.contentKey).slice(0, 5)} to={'/' + this.props.article.authors[0].slug} authorName={this.props.article.authors[0].name} />}
                    {/* Author Articles End */}
                    {this.props.article.relatedArticles.length > 0 && (<RelatedArticle relatedArticle={this.props.article.relatedArticles.slice(1)} unknownImage={unknownImage} />)}
                    <ArticleSocialShare className='inline-share-buttons' url={process.env.RAZZLE_SITE_URL + this.props.canonical} title={headline} quote={headline} media={resizeURL + socialImageSrcUrl} />
                  </div>
                  {this.state.hasMounted &&
                    <DesktopElse>
                      <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${this.props.location.pathname}`} slotId='leaderboard-2' targeting={{ leaderboard: '2' }} collapseEmptyDiv className='advert-leaderboard-2' {...FORMATS.leaderboard2} />
                    </DesktopElse>}
                  <div className='related-articles'>
                    {proposedArticles.length > 0 && (
                      <h2>You might also like</h2>
                    )}
                    {
                    proposedArticles.map((proposedArticle, index) => {
                      return (
                        <article key={index}>
                          <Link to={'/' + proposedArticle.getCanonicalUri()}>
                            <ImageOrNoImage image={proposedArticle.getImageObject()} width='240' shape='square' alt={proposedArticle.headline} noImage={unknownImage} />
                            <h3>
                              {proposedArticle.headline}
                            </h3>
                          </Link>
                        </article>
                      )
                    })
                  }
                  </div>
                  <section className='grid-2-2'>
                    <div className='OUTBRAIN' data-src={process.env.RAZZLE_SITE_URL + this.props.canonical} data-widget-id='AR_1' />
                  </section>
                </article>
                <Sidebar {...this.props} section={canonicalUri} contentKey={article.contentKey}>
                  <MagazineCover />
                </Sidebar>
              </div>
            </div>
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${this.props.location.pathname}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-3' {...FORMATS.leaderboard} />
            <script src='https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22491887924/Glamour/Video&description_url=https%3A%2F%2Fwww.glamour.co.za%2F&env=vp&impl=s&correlator=&tfcd=0&npa=0&gdfp_req=1&output=vast&sz=1x1|400x300|640x480&unviewed_position_start=1' type='text/javascript' async />
          </div>
        </>
      )
    } else {
      return (
        <>
          <Helmet title='Article' />
          <div className='main-article'>
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${this.props.location.pathname}`} slotId='leaderboard-1' targeting={{ leaderboard: '1' }} collapseEmptyDiv className='advert-leaderboard-1' {...FORMATS.leaderboard} />
            <ArticlePageSkeleton />
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${this.props.location.pathname}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-3' {...FORMATS.leaderboard} />
          </div>
        </>
      )
    }
  }
}

const mapStateToProps = (state) => ({ ...state.article, articles: state.articles })
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchArticle,
  setPreviewArticle,
  serverArticleRender,
  submitCompetition,
  fetchArticles
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Article)
