import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import Helmet from 'react-helmet'
import { getSectionLabel, url2section } from 'ion-sections'
import qs from 'qs'

import DefaultSectionPage from '../pages/DefaultSectionPage'
import GeneralHubSectionPage from '../pages/GeneralHubSectionPage'
import HoverZoomSectionPage from '../pages/HoverZoomSectionPage'
import ContentHubPage from '../pages/ContentHubPage'
import Article from '../pages/Article'
import NotFound from '../pages/NotFound'
import AuthorSectionPage from '../pages/AuthorSectionPage'
import CiaoMalfyHubSectionPage from '../pages/CiaoMalfyHubSectionPage'
import MacHubSectionPage from '../pages/MacHubSectionPage'
import BeautyAwardsPage from '../pages/BeautyAwards'

const DefaultSection = (props) => {
  const params = props.location ? qs.parse(props.location.search, { ignoreQueryPrefix: true }) : { page: 0 }
  const isPage0 = (params.page || 0) < 2

  return (
    <>
      <Helmet titleTemplate={props.titleTemplate} />
      <Switch>
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)/'} strict exact render={p => <Redirect to={props.match.url + '/' + p.match.params.section} />} />
        {props.match.url === props.location.pathname && (
          <Switch>
            <Route
              path='/fashion' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='fashion'
                  title='All the Latest Fashion and Style News | Glamour SA'
                  description='We have got you covered with the latest runway looks, fashion news, celebrity trends including innovative interviews with industry style experts we love and trust.'
                />}
            />
            <Route
              path='/fashion/trends' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='fashion/trends'
                  sectionLabel='Fashion Trends'
                  title="Glamour South Africa's fashion trends | Glamour South Africa"
                  description="If it's hot and trending, find out the most popular local and international celebrity, Pan-African & sustainable fashion updates on GLAMOUR South Africa."
                />}
            />
            <Route
              path='/fashion/celebrity-style' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='fashion/celebrity-style'
                  title='Fashion inspiration from your favourite celebs | Glamour South Africa'
                  description='Find out the newest celebrity style fashion firsthand & check out the outfits spotted by your favorite celebrities on the red carpet and in everyday life.'
                />}
            />
            <Route
              path='/fashion/accessories' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='fashion/accessories'
                  title="Hottest women's accessories & jewellery trends | Fashion | Glamour South Africa"
                  description="A glamour guide to the newest jewellery designers, shopping tips, and advice for women's accessories."
                />}
            />
            <Route
              path='/fashion/style-diary' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='fashion/style-diary'
                  title='Our favourite fashion forward fashionistas | Glamour South Africa'
                  description='See the latest style diaries from our favourite fashion-forward influencers of their outfitoftheday.'
                />}
            />
            <Route
              path='/beauty' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='beauty'
                  title='Latest Beauty News, Tips and Trends | Glamour SA'
                  description='If you want the latest in beauty, makeup, celebrity looks, hair and skincare trends, we’ve got you covered including life-changing beauty hacks we swear by.'
                />}
            />
            <Route
              path='/beauty/trends' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='beauty/trends'
                  sectionLabel='Beauty Trends'
                  title='Best hair, makeup, skincare, & beauty trends | Glamour South Africa'
                  description='Get access to the most up-to-date skin care routines, expert advice, video tutorials, and the latest beauty tips to help you look naturally flawless.'
                />}
            />
            <Route
              path='/beauty/hair' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='beauty/hair'
                  title='Natural hair styles, care tips & seasonal trends | Glamour South Africa'
                  description='Hair inspiration, style ideas, hair care guide, expert tips, and more to help you define your look.'
                />}
            />
            <Route
              path='/beauty/makeup' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='beauty/makeup'
                  title='Discover makeup tips to level up your beauty look | Glamour South Africa'
                  description='Your go-to guide for everything makeup-related, advice & techniques. Join the makeup revolution with Glamour South Africa.'
                />}
            />
            <Route
              path='/beauty/skincare' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='beauty/skincare'
                  sectionLabel='Skin Care'
                  title="Skincare routines, experts tips & how to's | Glamour South Africa"
                  description='The newest skin care products & routines including tips on how to use them correctly. Ask Glamour South Africa all your skin care questions.'
                />}
            />
            <Route
              path='/beauty/nails' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='beauty/nails'
                  title='The latest DIY, seasonal, and nail trend ideas | Glamour South Africa'
                  description="Inspired manicure ideas 2022. Read more about cute nails, gel & acrylic, tips & how to's as well as trending nail art."
                />}
            />
            <Route
              path='/beauty/beauty-awards' exact render={(p) =>
                <BeautyAwardsPage
                  {...props} {...p} isPage0={isPage0}
                  section='beauty/beauty-awards'
                  title="Get Ready To Level Up Your Beauty Game With Glamour South Africa's 2023 Beauty Awards"
                  description="Feast your eyes on the best beauty go-to guide for 2023! These are the beauties that superseded our beauty expectations this year; From makeup, skincare, body care, in-salon treatments & even our best local beauty buys, we've gone all out to bring you the crème de la crème of beauty."
                />}
            />
            <Route
              path='/celebrity-news' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='celebrity-news'
                  sectionLabel='Celeb News'
                  title='Get all the latest updates on South African & international celebs | Glamour South Africa'
                  description='Get the most recent celebrity news, fashion, entertainment, and more about South African and international celebs.'
                />}
            />
            <Route
              path='/lifestyle' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle'
                  title='The Latest Lifestyle News and Must-read Features | Glamour SA'
                  description='Everything lifestyle news that you need to know to live your best life. From where to go, what to eat and the coolest spots to take your next selfie for Instagram.'
                />}
            />
            <Route
              path='/lifestyle/careers' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/careers'
                  title="Glamour South Africa's career guide 101 tips and ways to refresh your career"
                  description='We share career choices and topics, as well as experiences with you to flourish in your working environment.'
                />}
            />
            <Route
              path='/lifestyle/food-drink' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/food-drink'
                  title='Healthy eating plans for a balanced lifestyle | Glamour South Africa'
                  description='With healthy eating guidelines, advice from the experts, and the newest food trends. We showcase how to enjoy a balanced lifestyle.'
                />}
            />
            <Route
              path='/lifestyle/travel' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/travel'
                  title='Travels Tips for the best local & international vacations | Glamour South Africa'
                  description='Travels Tips and Budget-friendly Destinations for local & international vacations.'
                />}
            />
            <Route
              path='/lifestyle/weddings' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/weddings'
                  title='Plan Your Dream Wedding with Our Bridal guides | Glamour South Africa'
                  description="Plan the wedding of your dreams using our bridal planning guides. You'll discover advice and DIY wedding planning ideas."
                />}
            />
            <Route
              path='/lifestyle/man-candy' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/man-candy'
                  title='Man Candy | Man Crush Monday with Glamour South Africa'
                  description="We interview South Africa's best men and feature the sexiest men every Monday with Glamour South Africa."
                />}
            />
            <Route
              path='/lifestyle/glamour-guides' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/glamour-guides'
                  sectionLabel='Glam Guides'
                  title='GLAMOUR Guides on Living Your Best Life | Glamour South Africa'
                  description='Join the GLAMOUR team in discovering your go to guide in living your best life.  We will keep you updated with everything glam and trending.'
                />}
            />
            <Route
              path='/lifestyle/glam-book-club' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/glam-book-club'
                  title='Glam Book Club | Lifestyle | Glamour'
                  description='Our Glamour Book Club connects avid readers, allowing like-minded women to engage and enjoy exclusive interviews, Q&A sessions, and virtual meet-and-greets for featured books. This allows readers to connect with authors and gain insights into their creative processes.'
                />}
            />
            <Route
              path='/lifestyle/women-in-charge' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle/women-in-charge'
                  title="Glamour South Africa profiles women entrepreneurs in trailblazing series 'Women in Charge'"
                  description="Discover the female entrepreneurs, business women and pioneers of Africa with our series 'Women In Charge' that focuses on profiling trailblazers in the business sector."
                />}
            />
            <Route
              path='/wellness' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='wellness'
                  title='Health and Wellness Tips and For a Healthy Lifestyle | Glamour SA'
                  description='Get everything you need to know about staying healthy and improving your overall health by incorporating expert wellness tips and advice on GLAMOUR South Africa.'
                />}
            />
            <Route
              path='/wellness/fitness-exercise' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='wellness/fitness-exercise'
                  title='Your go-to guide on all things fitness, health & body workouts | Glamour South Africa'
                  description='Get the body you want with advice on how to stay healthy with exercise routines and the newest wellness trends.'
                />}
            />
            <Route
              path='/wellness/love-relationships' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='wellness/love-relationships'
                  title='Love, Relationship Tips and Advice | Glamour South Africa'
                  description='GLAMOUR is your go-to place to keep the romance alive as we share the best relationship advice, and how to make your relationship work out.'
                />}
            />
            <Route
              path='/wellness/mindfulness' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='wellness/mindfulness'
                  title='Mindfulness, Mental Wellbeing, Awareness & self Care | Glamour South Africa'
                  description='Practicing mindfulness allows us to check in with ourselves, and prioritise our wellness. Read more about mental health.'
                />}
            />
            <Route
              path='/magazine' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='magazine'
                  title='Highlights from our Print Magazine Issue | Glamour SA'
                  description='Don’t get caught without the latest GLAMOUR magazine! We share all the cool reasons why you need to get your hands on our print issue on shelves now.'
                />}
            />
            <Route
              path='/events' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='events'
                  title='The Latest Updates on Our Exclusive Events | Glamour SA'
                  description='Feel like VIP! We give you full access to our GLAMOURous events. Have a look at all the A-listers attending and how you can secure your spot at the next one.'
                />}
            />
            <Route
              path='/win' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='win'
                  title='Enter Our Competitions and Give Aways and Win | Glamour SA'
                  description='Enter all our exciting competitions to stand a chance to win prizes so GLAMOURous that will make all your friends and frenemies jealous.'
                />}
            />
            <Route
              path='/test' exact render={(p) =>
                <>
                  <Helmet>
                    <meta name='robots' content='noindex, nofollow' />
                  </Helmet>
                  <DefaultSectionPage
                    {...props} {...p} isPage0={isPage0}
                    section='all-content-feed'
                    title='Test page | Glamour SA'
                    description='Test page for marketing purposes'
                  />
                </>}
            />
            <Route
              path='/more/gift-guide' exact render={(p) =>
                <HoverZoomSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/gift-guide'
                  title='Your Ultimate SA Gift Guide | Glamour South Africa'
                  description="Embark on a gifting journey filled with inspiration and delight with our comprehensive South African gift guide from Glamour South Africa. With an array of presents to suit every woman's style and personality."
                />}
            />
            <Route
              path='/more/sparkle-on-with-bernini' exact render={(p) =>
                <GeneralHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/sparkle-on-with-bernini'
                  title='Sparkle on with Bernini | Glamour South Africa'
                  description='more/sparkle-on-with-bernini'
                />}
            />
            <Route
              path='/more/glam-x-ariel' exact render={(p) =>
                <GeneralHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/glam-x-ariel'
                  title='glam-x-ariel | Glamour South Africa'
                  description='more/glam-x-ariel'
                />}
            />
            <Route
              path='/more/glam-x-ciao-malfy' exact render={(p) =>
                <CiaoMalfyHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/glam-x-ciao-malfy'
                  title='glam-x-ciao-malfy | Glamour South Africa'
                  description='more/glam-x-ciao-malfy'
                />}
            />
            <Route
              path='/more/glam-x-skip' exact render={(p) =>
                <CiaoMalfyHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/glam-x-skip'
                  title='glam-x-skip | Glamour South Africa'
                  description='more/glam-x-skip'
                />}
            />
            <Route
              path='/more/glam-x-mac' exact render={(p) =>
                <MacHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/glam-x-mac'
                  title='more/glam-x-mac | Glamour South Africa'
                  description='more/glam-x-mac'
                />}
            />
            <Route
              path='/more/glam-x-bulgari' exact render={(p) =>
                <GeneralHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/glam-x-bulgari'
                  title='glam-x-bulgari | Glamour South Africa'
                  description='more/glam-x-bulgari'
                />}
            />
            <Route
              path='/more/glams-most-glam' exact render={(p) =>
                <GeneralHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/glams-most-glam'
                  title='glams-most-glam | Glamour South Africa'
                  description='more/glams-most-glam'
                />}
            />
            <Route
              path='/more/style-your-smile' exact render={(p) =>
                <GeneralHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/style-your-smile'
                  title='style-your-smile | Glamour South Africa'
                  description='more/style-your-smile'
                />}
            />
            <Route
              path='/more/women-of-the-year' exact render={(p) =>
                <GeneralHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/women-of-the-year'
                  title='more | Glamour South Africa'
                  description='more/women-of-the-year'
                />}
            />
            <Route
              path='/more/how-do-i-look-sa' exact render={(p) =>
                <ContentHubPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/how-do-i-look-sa'
                  title='How Do I Look? SA | Glamour South Africa'
                  description='This is the description when Megan gets it.'
                />}
            />
            <Route
              path='/more/betway-summer-cup' exact render={(p) =>
                <MacHubSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='more/betway-summer-cup'
                  title='The Betway Summer Cup: Silks In The City | Glamour South Africa'
                  description='Get all the fashion and pop culture updates for the biggest social racing event of the year: The Betway Summercup with Glamour South Africa'
                />}
            />
            <Route
              path='/:section([a-zA-Z0-9/-]+)' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  title={getSectionLabel(url2section(props.location.pathname)) + ' | Glamour SA'}
                  section={url2section(props.location.pathname)} url={props.location.pathname}
                />}
            />
            <Route path='*' render={(p) => <NotFound {...props} {...p} />} />
          </Switch>
        )}
        <Route
          path='/authors/:section([a-zA-Z-]+)' render={(p) => <AuthorSectionPage
            {...props} {...p}
            isPage0={isPage0}
            section={'authors/' + p.match.params.section}
            url={props.location.pathname}
            authorSlug={p.match.params.section}
                                                              />}
        />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9]{5,})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)'} render={(p) => <DefaultSection {...props} {...p} />} />
      </Switch>
    </>
  )
}

export default DefaultSection
