import React from 'react'
import { Link } from 'react-router-dom'
import { StyledRelatedArticle } from './RelatedArticle.css'

const RelatedArticle = ({ relatedArticle, abstract }) => {
  return (
    <StyledRelatedArticle>
      <h2><span>GLAMOUR Recommends</span></h2>
      <ul>
        {relatedArticle.map(relatedArticle => (
          <li key={relatedArticle.id}>
            <article>
              <Link to={'/' + relatedArticle.externalUrl}>
                <h3>{relatedArticle.headline}</h3>
              </Link>
            </article>
          </li>
        ))}
      </ul>
    </StyledRelatedArticle>
  )
}

export default RelatedArticle
