import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Articles, SectionMeta } from 'ion-article-cmp'
import WingBanners from '../components/WingBanners'
import { getSectionLabel } from 'ion-sections'
import Ad from '../components/Ad'
import FORMATS from '../components/AdFormats'
import { MobileElse } from 'ion-media'
import logo from '../static/general/glamour.svg'
import NoImage from '../static/no-image.png'
import ArticlesNoAds from '../templates/Articles/ArticlesNoAds'
import NotFound from './NotFound'
import ContentHubArticleCard from '../components/ContentHubArticleCard'
import ContentHubHorArticleCard from '../components/ContentHubHorArticleCard'

const ContentHubPage = (props) => (
  <>
    <Helmet
      titleTemplate='%s'
      title={props.title || getSectionLabel(props.section)}
    >
      <meta property='fb:app_id' content='293175074032541' />
      <meta property='og:type' content='section' />
      <meta property='og:title' content={props.title || getSectionLabel(props.section)} />
      <meta property='og:image' content={logo} />
      <meta itemProp='headline' content={props.title || 'Latest News from ' + getSectionLabel(props.section)} />
      <meta name='description' content={props.description} />
      <meta itemProp='description' content={props.description} />
      <meta property='og:description' content={props.description} />
      <meta property='og:url' content={props.url} />
      <meta property='keywords' content={props.keywords} />
      <meta name='twitter:site' content={props.twitterName} />
      <meta name='twitter:creator' content={props.twitterName} />
      <meta name='twitter:title' content={props.title || getSectionLabel(props.section)} />
      <meta name='twitter:description' content={props.description} />
      <meta itemprop='image' content={logo} />
      <meta itemprop='thumbnailUrl' content={logo} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image:src' content={logo} />
    </Helmet>
    <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
    <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-1' targeting={{ leaderboard: '1' }} collapseEmptyDiv className='advert-leaderboard-1' {...FORMATS.leaderboard} />
    <div className='wrapper'>
      {props.isPage0 &&
        <Articles
          className='gq-best-dressed' {...props} section={props.section} count={13} pageSize={8} noImage={NoImage} notFound={NotFound} withMore
          component={({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton }) => (
            <>
              <div className='content-hub'>
                <div className='content-hub-main'>
                  <ContentHubArticleCard key={articles[0].contentKey} article={articles[0]} imageSize={670} noImage={NoImage} />
                </div>
                <div className='content-hub-round'>
                  {articles.slice(1, 5).map((article) => <ContentHubArticleCard key={article.contentKey} article={article} imageSize={150} noImage={NoImage} headlineOnly />)}
                </div>
              </div>
              <div className='content-hub-split'>
                {articles.slice(5, 7).map((article) => <ContentHubHorArticleCard key={article.contentKey} article={article} imageSize={670} noImage={NoImage} />)}
              </div>
              <div className='content-hub-list'>
                {articles.slice(7, 13).map((article) => <ContentHubArticleCard key={article.contentKey} article={article} imageSize={220} noImage={NoImage} />)}
                <aside>
                  <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${section}`} slotId='halfpage-1' targeting={{ halfpage: '1' }} collapseEmptyDiv className='advert-sidebar' desktop={[[300, 600]]} tablet={[[300, 600]]} mobile={[]} />
                </aside>
              </div>
              <div className='content-hub-more'>
                {articles.slice(13).map((article) => <ContentHubArticleCard key={article.contentKey} article={article} imageSize={220} noImage={NoImage} />)}
              </div>
              {moreButton}
            </>
          )}
        />}
      {!props.isPage0 &&
        <Articles className='content-hub-more paged' {...props} component={ArticlesNoAds} section={props.section} page0Limit={13} pageSize={12} noImage={NoImage} notFound={NotFound} withMore />}
    </div>
    <MobileElse>
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-3' {...FORMATS.leaderboard} />
    </MobileElse>
    <SectionMeta {...props} />
  </>
)

ContentHubPage.defaultProps = {
  component: ArticlesNoAds
}

const mapStateToProps = (state) => ({ articles: state.articles })

export default connect(mapStateToProps)(ContentHubPage)
