import React, { Component } from 'react'
import { StyledMagazineCoverOuter, StyledInnerDiv, StyledCoverImage, StyledDescriptionDiv, StyledSubscribeButton } from './SubscribeBlock.css'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchSubscribeBlock } from '../../store/subscribeBlock'

export class SubscribeBlock extends Component {
  constructor (props) {
    super(props)
    if (!this.props.subscribeBlock) {
      this.props.fetchSubscribeBlock()
    }
    console.log(this.props.image)
  }

  render () {
    return (
      <StyledMagazineCoverOuter>
        <StyledInnerDiv>
          <StyledCoverImage>
            <Link to='/subscribe-glamour'>
              {this.props.image &&
                <img src={this.props.image} alt='magazine cover' />}
            </Link>
          </StyledCoverImage>
          <StyledDescriptionDiv>
            <p className='top-text'>{this.props.topText}</p>
            <p className='save-text'><em>{this.props.saveText}</em></p>
            <p className='percentage'><strong><em>{this.props.savePercentage}</em></strong></p>
            <StyledSubscribeButton href={this.props.subscribeLink} target='_blank' rel='noopener noreferrer'>
              Subscribe
            </StyledSubscribeButton>
          </StyledDescriptionDiv>
        </StyledInnerDiv>
      </StyledMagazineCoverOuter>
    )
  }
}

const mapStateToProps = (state) => (Object.assign({}, state.subscribeBlock.subscribeBlock))

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSubscribeBlock
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeBlock)
